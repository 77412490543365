//column-base selectors
//.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12
%column-base {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}
.container {
  margin-right: auto;
  margin-left: auto;
}
.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  // margin-right: -0.5rem;
  // margin-left: -0.5rem;
  &.reverse {
    flex-direction: row-reverse;
  }
}
.col-xs {
  @extend %column-base;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.col-xs-1 {
  @extend %column-base;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.col-xs-2 {
  @extend %column-base;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.col-xs-3 {
  @extend %column-base;
  flex-basis: 25%;
  max-width: 25%;
}
.col-xs-4 {
  @extend %column-base;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.col-xs-5 {
  @extend %column-base;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.col-xs-6 {
  @extend %column-base;
  flex-basis: 50%;
  max-width: 50%;
}
.col-xs-7 {
  @extend %column-base;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.col-xs-8 {
  @extend %column-base;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.col-xs-9 {
  @extend %column-base;
  flex-basis: 75%;
  max-width: 75%;
}
.col-xs-10 {
  @extend %column-base;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.col-xs-11 {
  @extend %column-base;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.col-xs-12 {
  @extend %column-base;
  flex-basis: 100%;
  max-width: 100%;
}
.col-xs-offset-0 {
  @extend %column-base;
  margin-left: 0;
}
.col-xs-offset-1 {
  @extend %column-base;
  margin-left: 8.33333333%;
}
.col-xs-offset-2 {
  @extend %column-base;
  margin-left: 16.66666667%;
}
.col-xs-offset-3 {
  @extend %column-base;
  margin-left: 25%;
}
.col-xs-offset-4 {
  @extend %column-base;
  margin-left: 33.33333333%;
}
.col-xs-offset-5 {
  @extend %column-base;
  margin-left: 41.66666667%;
}
.col-xs-offset-6 {
  @extend %column-base;
  margin-left: 50%;
}
.col-xs-offset-7 {
  @extend %column-base;
  margin-left: 58.33333333%;
}
.col-xs-offset-8 {
  @extend %column-base;
  margin-left: 66.66666667%;
}
.col-xs-offset-9 {
  @extend %column-base;
  margin-left: 75%;
}
.col-xs-offset-10 {
  @extend %column-base;
  margin-left: 83.33333333%;
}
.col-xs-offset-11 {
  @extend %column-base;
  margin-left: 91.66666667%;
}
.col-xs-offset-12 {
  @extend %column-base;
}
.start-xs {
  justify-content: flex-start;
  text-align: start;
}
.center-xs {
  justify-content: center;
  text-align: center;
}
.end-xs {
  justify-content: flex-end;
  text-align: end;
}
.top-xs {
  align-items: flex-start;
}
.middle-xs {
  align-items: center;
}
.bottom-xs {
  align-items: flex-end;
}
.around-xs {
  justify-content: space-around;
}
.between-xs {
  justify-content: space-between;
}
.first-xs {
  order: -1;
}
.last-xs {
  order: 1;
}
@media only screen and(min-width: 48em) {
  .container {
    width: 49rem;
  }
  .col-sm {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .col-sm-offset-1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333333%;
  }
  .col-sm-offset-2 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66666667%;
  }
  .col-sm-offset-3 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333333%;
  }
  .col-sm-offset-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66666667%;
  }
  .col-sm-offset-6 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333333%;
  }
  .col-sm-offset-8 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66666667%;
  }
  .col-sm-offset-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333333%;
  }
  .col-sm-offset-11 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66666667%;
  }
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    align-items: flex-start;
  }
  .middle-sm {
    align-items: center;
  }
  .bottom-sm {
    align-items: flex-end;
  }
  .around-sm {
    justify-content: space-around;
  }
  .between-sm {
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}
@media only screen and(min-width: 64em) {
  .container {
    width: 65rem;
  }
  .col-md {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-0 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .col-md-offset-1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333333%;
  }
  .col-md-offset-2 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66666667%;
  }
  .col-md-offset-3 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-md-offset-4 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333333%;
  }
  .col-md-offset-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66666667%;
  }
  .col-md-offset-6 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-md-offset-7 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333333%;
  }
  .col-md-offset-8 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66666667%;
  }
  .col-md-offset-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-md-offset-10 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333333%;
  }
  .col-md-offset-11 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66666667%;
  }
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .start-md {
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    justify-content: center;
    text-align: center;
  }
  .end-md {
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    align-items: flex-start;
  }
  .middle-md {
    align-items: center;
  }
  .bottom-md {
    align-items: flex-end;
  }
  .around-md {
    justify-content: space-around;
  }
  .between-md {
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}
@media only screen and(min-width: 75em) {
  .container {
    width: 76rem;
  }
  .col-lg {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .col-lg-offset-1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333333%;
  }
  .col-lg-offset-2 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66666667%;
  }
  .col-lg-offset-3 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333333%;
  }
  .col-lg-offset-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66666667%;
  }
  .col-lg-offset-6 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333333%;
  }
  .col-lg-offset-8 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66666667%;
  }
  .col-lg-offset-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333333%;
  }
  .col-lg-offset-11 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66666667%;
  }
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    align-items: flex-start;
  }
  .middle-lg {
    align-items: center;
  }
  .bottom-lg {
    align-items: flex-end;
  }
  .around-lg {
    justify-content: space-around;
  }
  .between-lg {
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}
.col.reverse {
  flex-direction: column-reverse;
}
