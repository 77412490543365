$font-header: "Montserrat", "Raleway", "Cambria", Georgia, serif;
$font-body: "Comfortaa", "Raleway", "Hel­vetica", Arial, sans-serif;
$font-code: "Menlo", "Monaco", "Andale Mono", "lucida console", "Courier New", monospace;

// Modular font scale
// Large: http://www.modularscale.com/?1.25&em&1.333&web&text
// Medium: http://www.modularscale.com/?1.25&em&1.25&web&text
// SMall: http://www.modularscale.com/?1.1&em&1.25&web&text

$font-scale-large: (
  h1: 2.961rem,
  h2: 2.221rem,
  h3: 1.666rem,
  p: 1.25rem
);

$font-scale-medium: (
  h1: 2.441rem,
  h2: 1.953em,
  h3: 1.563rem,
  p: 1.25rem,
);

$font-scale-small: (
  h1: 2.148rem,
  h2: 1.719rem,
  h3: 1.375rem,
  p: 1.1rem
);

$base-line-height: 1.6;
$base-font-size: 1.25rem; // 20px
$vertical-rhythm: $base-line-height * $base-font-size; // 2.125em or 34px

$breakpoint-medium: 48em; // 768px
$breakpoint-small: 25em; // 400px

@mixin size($level) {
  font-size: map-get($font-scale-large, $level);
  @media (max-width: $breakpoint-medium) {
    font-size: map-get($font-scale-medium, $level);
  }
  @media (max-width: $breakpoint-small) {
    font-size: map-get($font-scale-small, $level);
  }
}

h1 {
  @include size(h1);
}

h2 {
  @include size(h2);
}

h3 {
  @include size(h3);
}
