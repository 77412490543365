* {
  margin: 0;
  padding: 0;
  border: 0;
  color: $black;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  box-sizing: inherit;
  overflow-x: hidden;
}

@mixin keyframes($value) {
  @-webkit-keyframes #{$value} {
    @content;
  }
  @-moz-keyframes #{$value} {
    @content;
  }
  @-o-keyframes #{$value} {
    @content;
  }
  @keyframes #{$value} {
    @content;
  }
}

body {
  @include size(p);
  color: $black;
  font-family: $font-body;
  line-height: $base-line-height;
}

header {
  height: 100vh;
  background-color: $white;

  div { z-index: 101; }
  img { max-height: $vertical-rhythm * 6; }
  h1 { font-family: $font-body; }
  .cursor { animation: 1s blink step-end infinite; }
  a { 
    padding-top: 70px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #000;
    font : normal 400 20px/1 sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;
    &:hover {
      opacity: .5;
    } 
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      animation: 1.5s scroll infinite;
      box-sizing: border-box;
    }
  }
}
