@import url("https://fonts.googleapis.com/css?family=Comfortaa|Montserrat|Raleway");
@import "flexboxgrid";
@import "colors";
@import "typography";
@import "layout";
@import "base";
@import "animate";

@media(max-width: 48em) {
  body .container {
    padding: $vertical-rhythm * 2 $vertical-rhythm;
  }
  nav {
    // Hide menu links between logo and hamburger menu
    div:not(:first-child):not(:last-child) {
      display: none;
    }
    div.nav-toggle a {
      display: inline-block;
    }
  }
}
@media(max-width: 25em) {
  body .container {
    padding: $vertical-rhythm * 2 $vertical-rhythm;
  }
  nav {
    div.logo, div.nav-toggle {
      max-width: 50% !important;
    }
  }
}

@include keyframes("blink"){
  from, to { color: transparent; }
  50% { color: $black; }
}

@include keyframes("scroll"){
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

nav {
  height: $vertical-rhythm * 2;
  width: 100%;
  background-color: #FFF;
  position: absolute;
  bottom: 0;
  z-index: 100;
  text-align: center;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .4 );
  h3 {
    margin: 0;
    font-family: $font-body;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
  }
  img {
    display: inline-block;
    max-width: 10em;
    max-height: 2.5em;
    vertical-align: middle;
    height: 180px;
  }
  div.logo, div.nav-toggle {
    visibility: hidden;
    max-width: none;
  }
  .nav-toggle a {
    display: none;
  }
}
.nav-fixed {
  position: fixed;
  top: 0;
  bottom: auto;
}
.nav-full {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  right: -100vw;
  height: 100%;
  background-color: $black;
  transform: translate(0px, 0px);
  transition: 0.25s ease-out;
  a {
    color: #FFF;
    &:hover {
      color: $accent;
    }
  }
}
.nav-full.active {
  transform: translate(-100vw, 0px);
}
main {
  background-color: #fff;
  transform: translate(0px, 0px);
  transition: 0.25s ease-in;
}
main.active {
  transform: translate(-100vw, 0px);
}
.container {
  max-width: 43.750rem; // 700px
  padding: $vertical-rhythm * 2 0 $vertical-rhythm * 2;
  margin: auto;
}
p {
  margin-bottom: $vertical-rhythm;
  code {
    font-family: $font-code;
  }
}
h1, h2, h3 {
  font-family: $font-header;
  margin-top: $vertical-rhythm;
  letter-spacing: 2px;
  a {
    text-decoration: none;
  }
}

.posts-list {
  margin: 0 0 $vertical-rhythm;
}
.sub-header, time {
  @include size(p);
  color: $storm;
  margin-bottom: $vertical-rhythm;
}
.content {
  text-align: left;
  width: 100%;
  time {
    margin-left: 3px;
  }
  a {
    text-shadow: 0.03em 0 #fff,
    -0.03em 0 #fff,
    0 0.03em #fff,
    0 -0.03em #fff,
    0.06em 0 #fff,
    -0.06em 0 #fff,
    0.09em 0 #fff,
    -0.09em 0 #fff,
    0.12em 0 #fff,
    -0.12em 0 #fff,
    0.15em 0 #fff,
    -0.15em 0 #fff,
    0.03em 0.075em #fff,
    -0.03em 0.075em #fff,
    0.06em 0.075em #fff,
    -0.06em 0.075em #fff,
    0.09em 0.075em #fff,
    -0.09em 0.075em #fff,
    0.12em 0.075em #fff,
    -0.12em 0.075em #fff,
    0.15em 0.075em #fff,
    -0.15em 0.075em #fff;
    background-image: linear-gradient($accent,$accent);
    background-size: 1px 2px;
    background-repeat: repeat-x;
    background-position: 0 95%;
    text-decoration: none;
    &:hover {
      color: $accent;
    }
  }
}
.highlight {
  margin: 10px 0;
}
.pagination {
  margin: 50px 0 0;
  :nth-child(2) {
    float: right;
  }
}
.full {
  height: 100vh;
  top: 0;
  bottom: 0;
}
.about {
  width: 100%;
  background-color: $smoke;
  padding-bottom: $vertical-rhythm * 2;
  p {
    @include size(h3);
    margin-top: $vertical-rhythm;
  }
  a {
    text-shadow: 0.03em 0 #fff,
    -0.03em 0 #fff,
    0 0.03em #fff,
    0 -0.03em #fff,
    0.06em 0 #fff,
    -0.06em 0 #fff,
    0.09em 0 #fff,
    -0.09em 0 #fff,
    0.12em 0 #fff,
    -0.12em 0 #fff,
    0.15em 0 #fff,
    -0.15em 0 #fff,
    0.03em 0.075em #fff,
    -0.03em 0.075em #fff,
    0.06em 0.075em #fff,
    -0.06em 0.075em #fff,
    0.09em 0.075em #fff,
    -0.09em 0.075em #fff,
    0.12em 0.075em #fff,
    -0.12em 0.075em #fff,
    0.15em 0.075em #fff,
    -0.15em 0.075em #fff;
    background-image: linear-gradient($accent,$accent);
    background-size: 2px 3px;
    background-repeat: repeat-x;
    background-position: 0 95%;
    text-decoration: none;
    &:hover {
      color: $accent;
    }
  }
}
.gallery {
  div[class^="col-"], div[class*=" col-"] {
    padding: 0;
    position: relative;
    &:hover .overlay {
      opacity: 1;
    }
  }
  img {
    padding: $vertical-rhythm * 2;
  }
}
.overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .2s ease-out;
  background-color: $black;
  a {
    color: #FFF;
    &:hover {
      color: $accent;
    }
  }
  h2 {
    color: #FFF;
  }
  h3 {
    font-family: $font-body;
  }
}
.blog {
  min-height: 100vh;
  h3 {
    margin: 0 0 $vertical-rhythm;
    font-family: $font-body;
  }
}

.contact {
  width: 100%;
  background-color: $smoke;
  padding-bottom: $vertical-rhythm * 2;

  form {
    margin-top: $vertical-rhythm;
  }

  .contact-submit {
    position: relative;
  }

  #form-submit {
    display: block;
    margin: 0 auto;
    width: 50%;
  }

  #form-thankyou {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

input {
  background-color: #FFF;
  border-color: $smoke;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  color: $black;
  padding: 10px;
  margin: 8px 0;
  width: 100%;
  &[type="submit"] {
    background-color: $storm;
    color: #FFF;
    transition: .2s ease-out;
    &:hover {
      background-color: $accent;
      color: #FFF;
      cursor: pointer;
    }
  }
}
textarea {
  @extend input;
  min-height: $vertical-rhythm * 4;
  overflow: auto;
}
footer {
  height: $vertical-rhythm * 6;
  background-color: $black;
  div, a {
    color: #FFF;
  }
  font-family: $font-body;
  h4 {
    color: $smoke;
  }
  p {
    color: $white;
    font-size: 12px;
    margin-bottom: 2px;
  }
}

.colore {
  color: $accent;
}

.eye {
  color: $smoke;
}

.stack {
  ul.int {
    list-style: none;
  }
  li {
    list-style: none;
  }
  li:before {
    content: ">_   ";
    color: $accent; 
  }
}

.stack__promo {
  ul.int {
    list-style: none;
  }
  li {
    list-style: none;
    font-size: 1.6em;
    padding-left: 14px;
    p {
      padding-left: 20px;
      font-size: 0.5em;
    }
  }
  li:before {
    content: ">_ ";
    color: $accent; 
  }
}
